// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-tsx": () => import("/Users/artur/reactJsWorkbench/graefenstein-homepage/src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("/Users/artur/reactJsWorkbench/graefenstein-homepage/src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-impressum-tsx": () => import("/Users/artur/reactJsWorkbench/graefenstein-homepage/src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("/Users/artur/reactJsWorkbench/graefenstein-homepage/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("/Users/artur/reactJsWorkbench/graefenstein-homepage/src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */)
}

